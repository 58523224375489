/* eslint-disable react/no-danger */
import { Row } from "antd";
import React from "react";

import "./Slide.scss";
import Logo from "../../../../components/common/Logo";
import SadIllustration1 from "../../../../assets/img/sad-1.png";
import SadIllustration2 from "../../../../assets/img/sad-2.png";
import SadIllustration3 from "../../../../assets/img/sad-3.png";
import SadIllustration4 from "../../../../assets/img/sad-4.png";
import SadIllustration5 from "../../../../assets/img/sad-5.png";
import Illustration1 from "../../../../assets/img/illustration_1.png";
import Illustration2 from "../../../../assets/img/illustration_2.png";
import Illustration3 from "../../../../assets/img/illustration_3.png";
import Illustration4 from "../../../../assets/img/illustration_4.png";
import Illustration5 from "../../../../assets/img/illustration_5.png";

const IllustrationScale = {
  1: {
    pass: Illustration1,
    fail: SadIllustration1,
  },
  2: {
    pass: Illustration2,
    fail: SadIllustration2,
  },
  3: {
    pass: Illustration3,
    fail: SadIllustration3,
  },
  4: {
    pass: Illustration4,
    fail: SadIllustration4,
  },
  5: {
    pass: Illustration5,
    fail: SadIllustration5,
  },
};

const ResultCard = ({
  result,
  theme,
  issueCategory,
  style = {},
  cardNumber,
}) => (
  <div style={style} className={`result-card ${theme}`}>
    {result && <p dangerouslySetInnerHTML={{ __html: result.statement }} />}
    <Row className="result-card-footer">
      <div className="result-logo">
        {/* <span className="fact-number">Fact - {cardNumber}</span> */}
        <Logo />
      </div>
      <div className="result-illustration">
        {result?.isPositive ? (
          <img
            src={IllustrationScale[issueCategory.issue].pass}
            className={`slide-${issueCategory.issue}`}
            alt=""
          />
        ) : (
          <img
            src={IllustrationScale[issueCategory.issue].fail}
            className={`slide-${issueCategory.issue}`}
            alt=""
          />
        )}
      </div>
    </Row>
  </div>
);

export default ResultCard;
