import React from "react";
import { Row, Col, Form, InputNumber, Button } from "antd";

import "./Age.scss";
import locales from "../../../locales/index.json";
import useHandlers from "../../../hooks/useHandlers";
import useUtilities from "../../../hooks/useUtilities";
import backDropSrc from "../../../assets/img/backdrop-2.png";

const AgeForm = () => {
  const { handleSubmitAge } = useHandlers();
  const { selectedLanguage } = useUtilities();

  return (
    <div className="status-form age-form-container">
      <Row className="justify-center">
        <Col className="z-10">
          <Col className="justify-center">
            <Col className="heading">
              <h2>{locales.tellUsAboutYouTitle[selectedLanguage]}</h2>
              <p>{locales.tellUsAboutYouDescription[selectedLanguage]}</p>
            </Col>
            <Col>
              <Form
                layout="vertical"
                className="age-form"
                onFinish={handleSubmitAge}
              >
                <Form.Item
                  name="age"
                  rules={[{ required: true, message: "Please enter your age" }]}
                >
                  <InputNumber
                    min={0}
                    max={180}
                    name="ageInput"
                    placeholder={locales.tellUsAgeTitle[selectedLanguage]}
                  />
                </Form.Item>
                <div className="btn-wrapper">
                  <Button htmlType="submit" className="status-btn">
                    {locales.nextText[selectedLanguage]}
                  </Button>
                </div>
              </Form>
            </Col>
          </Col>
        </Col>
        <div className="backdrop">
          <img src={backDropSrc} alt="background illustration" />
        </div>
      </Row>
    </div>
  );
};

export default AgeForm;
