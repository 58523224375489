/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-curly-newline */
import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Row, Col, Form, Select, Button } from "antd";

import "./Country.scss";
import locales from "../../../locales/index.json";
import useHandlers from "../../../hooks/useHandlers";
import useUtilities from "../../../hooks/useUtilities";
import backDropSrc from "../../../assets/img/backdrop-2.png";

const { Option } = Select;

const Country = () => {
  const { handleSubmitCountry } = useHandlers();
  const { countries, selectedLanguage, isCountriesLoading } = useUtilities();

  return (
    <div className="status-form country-form-status">
      <Row className="justify-center">
        <Col className="z-10" xs={22} lg={24} xl={24}>
          <Row className="justify-center">
            <Col xl={17} className="heading mb-2rem">
              <h2>{locales.selectCountryTitle[selectedLanguage]}</h2>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
              {isCountriesLoading ? (
                <Row className="justify-center">
                  <Col lg={1}>
                    <LoadingOutlined style={{ fontSize: "2rem" }} />
                  </Col>
                </Row>
              ) : (
                <Form
                  layout="vertical"
                  className="country-form-container"
                  onFinish={handleSubmitCountry}
                >
                  <Form.Item
                    name="country"
                    rules={[
                      { required: true, message: "Please select a country" },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder={
                        locales.selectCountryTitle[selectedLanguage]
                      }
                      optionFilterProp="children"
                      dropdownClassName="country-select-dropdown"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                      {countries.length > 0 &&
                        countries.map((country) => (
                          <Option value={country.id} key={country.id}>
                            {country.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <div className="btn-wrapper">
                    <Button htmlType="submit" className="status-btn">
                      {locales.nextText[selectedLanguage]}
                    </Button>
                  </div>
                </Form>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="backdrop">
        <img src={backDropSrc} alt="background illustration" />
      </div>
    </div>
  );
};

export default Country;
