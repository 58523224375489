import React from "react";

const IssueCard = ({ title, image, variant, selectIssue, id }) => (
  <div
    className={`issue-card ${variant}`}
    onClick={() => selectIssue(id)}
    aria-hidden="true"
  >
    <h3>{title}</h3>
    <div className="card-image" style={{ backgroundImage: `url(${image})` }} />
  </div>
);

export default IssueCard;
