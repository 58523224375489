import { useContext } from "react";
import { useQuery } from "react-query";

import * as api from "../api";
import { ENDPOINTS } from "../utils/constants";
import { AppContext } from "../store/AppContext";

const useUtilities = () => {
  const { selectedLanguage } = useContext(AppContext);

  const { data: issuesData, isLoading: isIssuesLoading } = useQuery(
    [ENDPOINTS.issues, { language: selectedLanguage }],
    api.getResource
  );

  const { data: countriesData, isLoading: isCountriesLoading } = useQuery(
    [ENDPOINTS.countries, { language: selectedLanguage }],
    api.getResource
  );

  const { data: regionsData, isLoading: isRegionsLoading } = useQuery(
    [ENDPOINTS.regions, { language: selectedLanguage }],
    api.getResource
  );

  return {
    selectedLanguage,
    isIssuesLoading,
    isRegionsLoading,
    isCountriesLoading,
    issues: issuesData ? issuesData.data.issues : [],
    regions: regionsData ? regionsData.data.regions : [],
    countries: countriesData ? countriesData.data.countries : [],
  };
};

export default useUtilities;
