import React from "react";
import { Link } from "react-router-dom";
import LogoImg from "../../assets/img/logo.png";

const Logo = () => (
  <Link to="/">
    <img src={LogoImg} alt="logo" />
  </Link>
);

export default Logo;
