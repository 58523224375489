import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import React, { useContext, useEffect } from "react";

import "./Info.scss";
import locales from "../../locales/index.json";
import { AppContext } from "../../store/AppContext";
import PageLayout from "../../components/common/PageLayout";
import { findOutMore, RESULT } from "../../utils/constants";

const Info = ({ match }) => {
  const issueId = Number(match.params.id);
  const { updateNav, selectedLanguage } = useContext(AppContext);
  const { note, details } = findOutMore[selectedLanguage][`issue${issueId}`];

  useEffect(() => {
    updateNav(RESULT);
    // eslint-disable-next-line
  }, []);

  return (
    <PageLayout className="info-container">
      <div className="info-content-container">
        <Row className="justify-center">
          <Col lg={8}>
            <div className="heading">
              <h3>{locales.findOutMore[selectedLanguage]}</h3>
            </div>
          </Col>
        </Row>
        <Row className="justify-center">
          <Col lg={14} xl={11}>
            <div className="content">
              <em>{note}</em>
              <ul>
                {details.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
            <Row className=" justify-center">
              <Col lg={10} xl={8} className="info-link-btn">
                <Link to="/status">Back to results</Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
};

export default Info;
