import React from "react";

import ArrowIcon from "../../assets/img/icons/arrow-right.svg";
import FileIcon from "../../assets/img/icons/file.png";
import "./Explore.scss";

const ExploreCard = ({
  language,
  title,
  bodyContent,
  resourceLink,
  ogImage,
}) => (
  <div className="explore-card">
    <div className="card-image">
      <img
        src={ogImage || FileIcon}
        alt="file icon"
        className={ogImage ? "content" : "no-content"}
      />
    </div>
    <div className="card-content-wrapper">
      <div className="language">
        <p>{language}</p>
      </div>
      <div className="card-heading">
        <h3>{title}</h3>
      </div>
      <div className="card-body">
        <p>{bodyContent}</p>
      </div>
      <div className="card-footer">
        <a href={resourceLink} target="_blank" rel="noreferrer">
          <span>Read More</span>
          <img src={ArrowIcon} alt="" />
        </a>
      </div>
    </div>
  </div>
);

export default ExploreCard;
