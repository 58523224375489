/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "antd";
import React, { useState, useEffect, useContext } from "react";

import "./Slide.scss";
import ResultCard from "./result-card";
import { AppContext } from "../../../../store/AppContext";

const ResultSlide = ({ results, theme, prepareThumbnail }) => {
  const { issueCategory } = useContext(AppContext);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [resultsStyle, setResultStyle] = useState([]);

  const updateStyleState = () =>
    results.map((item, index) => ({
      zIndex: results.length - index,
      translateValue: screenWidth > 600 ? index * -410 : index * -95,
      scale: 1 + index * -0.05,
      transform:
        screenWidth > 600
          ? `translateX(${index * -410}px) scale(${1 + index * -0.05})`
          : `translateX(${index * -95}%) scale(${1 + index * -0.05})`,
      visibility: screenWidth < 600 && (index > 0 ? "hidden" : "visible"),
    }));

  window.addEventListener("resize", () => {
    setScreenWidth(window.innerWidth);
  });

  const prevButton = () => {
    if (currentSlide > 0) {
      const newArr = resultsStyle.map((item, index) => {
        if (index + 1 === currentSlide) {
          // For the progress bar
          document.querySelectorAll(".progress-bar span")[
            currentSlide
          ].style.backgroundColor = "#828282";
          document.querySelectorAll(".progress-bar span")[
            currentSlide - 1
          ].style.backgroundColor = "#4DBBB5";

          setCurrentSlide(currentSlide - 1);
          return {
            ...item,
            translateValue: item.translateValue - 850,
            scale: item.scale - 0.05,
            transform:
              screenWidth > 600
                ? `translateX(${item.translateValue - 850}px) scale(${
                    item.scale - 0.05
                  })`
                : `translateX(${item.translateValue - 850}%) scale(${
                    item.scale - 0.05
                  })`,
            visibility: screenWidth < 600 && "visible",
          };
        }
        return {
          ...item,
          translateValue:
            screenWidth > 600
              ? item.translateValue + 30
              : item.translateValue + 5,
          scale: item.scale - 0.05,
          transform:
            screenWidth > 600
              ? `translateX(${item.translateValue + 30}px) scale(${
                  item.scale - 0.05
                })`
              : `translateX(${item.translateValue + 5}%) scale(${
                  item.scale - 0.05
                })`,
          visibility:
            screenWidth < 600 &&
            (currentSlide - 1 === index ? "visible" : "hidden"),
        };
      });
      setResultStyle(newArr);
    }
  };

  const nextButton = () => {
    if (currentSlide < resultsStyle.length - 1) {
      const newArr = resultsStyle.map((item, index) => {
        if (index === currentSlide) {
          // For the progress bar
          document.querySelectorAll(".progress-bar span")[
            currentSlide + 1
          ].style.backgroundColor = "#4DBBB5";
          document.querySelectorAll(".progress-bar span")[
            currentSlide
          ].style.backgroundColor = "#828282";

          setCurrentSlide(currentSlide + 1);
          return {
            ...item,
            translateValue: item.translateValue + 850,
            scale: item.scale + 0.05,
            transform: `translateX(${item.translateValue + 850}px) scale(${
              item.scale + 0.05
            })`,
          };
        }
        return {
          ...item,
          translateValue:
            screenWidth > 600
              ? item.translateValue - 30
              : item.translateValue - 5,
          scale: item.scale + 0.05,
          transform:
            screenWidth > 600
              ? `translateX(${item.translateValue - 30}px) scale(${
                  item.scale + 0.05
                })`
              : `translateX(${item.translateValue - 5}%) scale(${
                  item.scale + 0.05
                })`,
          visibility:
            screenWidth < 600 &&
            (index === currentSlide + 1 ? "visible" : "hidden"),
        };
      });
      setResultStyle(newArr);
    }
  };

  useEffect(() => {
    setResultStyle(updateStyleState());
    setCurrentSlide(0);
  }, [screenWidth]);

  useEffect(() => {
    prepareThumbnail();
  }, []);

  return (
    <div className={`slide-container  ${theme}`}>
      <div className="nav-left">
        <Button className="slide-btn" onClick={prevButton}>
          <svg
            width="14"
            height="22"
            viewBox="0 0 14 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3861 20.8528C13.7511 20.1228 13.5979 19.2402 13.0083 18.6758L5.25 11.25L13.054 3.446C13.6189 2.88106 13.759 2.018 13.4017 1.3034C12.8351 0.170219 11.3249 -0.0748608 10.429 0.820996L0 11.25L10.4259 21.3568C11.3294 22.2326 12.8234 21.9782 13.3861 20.8528Z"
              fill="black"
            />
          </svg>
        </Button>
      </div>
      <div className="result-card-wrapper" id="centered-content">
        {results.map((result, index) => (
          <ResultCard
            cardNumber={index + 1}
            // eslint-disable-next-line react/no-array-index-key
            key={result.statement + index}
            result={result}
            issueCategory={issueCategory}
            style={resultsStyle[index]}
            theme={theme}
          />
        ))}
      </div>
      <div className="nav-right">
        <Button className="slide-btn" onClick={nextButton}>
          <svg
            width="14"
            height="22"
            viewBox="0 0 14 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.613885 20.8528C0.248894 20.1228 0.402057 19.2402 0.991655 18.6758L8.75 11.25L0.945997 3.446C0.381057 2.88106 0.241001 2.018 0.598301 1.3034C1.16489 0.170219 2.67514 -0.0748608 3.571 0.820996L14 11.25L3.57406 21.3568C2.67063 22.2326 1.17659 21.9782 0.613885 20.8528Z"
              fill="black"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default ResultSlide;
