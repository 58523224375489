import { useLocation } from "react-router-dom";
import React, { useContext, useEffect } from "react";

import "./Status.scss";
import AgeForm from "./Age";
import Issue from "./Issue";
import Country from "./Country";
import Results from "./Results";
import { AppContext } from "../../store/AppContext";
import PageLayout from "../../components/common/PageLayout";
import { AGE, COUNTRY, ISSUE, RESULT } from "../../utils/constants";

function useURLQuery() {
  return new URLSearchParams(useLocation().search);
}

const handleStatusContentDisplay = (value) => {
  if (value === ISSUE) return <Issue />;
  if (value === RESULT) return <Results />;
  if (value === COUNTRY) return <Country />;
  return <AgeForm />;
};

const Status = () => {
  const { currentNav, updateNav, updateIssue } = useContext(AppContext);
  const query = useURLQuery();
  const [age, issue, country] = [
    query.get(AGE),
    query.get(ISSUE),
    query.get(COUNTRY),
  ];

  useEffect(() => {
    if (!!age && !!country && !!issue) {
      updateIssue({ age, country, issue });
      updateNav(RESULT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [age, country, issue]);

  return (
    <PageLayout page="status-page">
      <div className="status-container">
        <div className="status-content">
          {handleStatusContentDisplay(currentNav)}
        </div>
      </div>
    </PageLayout>
  );
};

export default Status;
