import React from "react";
import { Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "./Issue.scss";
import IssueCard from "./IssueCard";
import locales from "../../../locales/index.json";
import useHandlers from "../../../hooks/useHandlers";
import useUtilities from "../../../hooks/useUtilities";
import { COLOR_CODE_MAP, ILLUSTRATION_MAP } from "../../../utils/constants";

const Issue = () => {
  const { handleSelectedIssue } = useHandlers();
  const { issues, isIssuesLoading, selectedLanguage } = useUtilities();

  return (
    <div className="status-form issue-form-container">
      <Row className="justify-center">
        <Col xl={24}>
          <Row className="justify-center issue-row">
            <Col xs={22} xl={17} className="heading">
              <h2>{locales.chooseIssueTitle[selectedLanguage]}</h2>
            </Col>
            <Col xs={24} md={24}>
              {isIssuesLoading ? (
                <Row justify="center">
                  <Col lg={1}>
                    <LoadingOutlined style={{ fontSize: "2rem" }} />
                  </Col>
                </Row>
              ) : (
                <div className="issue-card-container">
                  {issues.map((issue, index) => (
                    <IssueCard
                      id={issue.id}
                      key={issue.id}
                      title={issue.description}
                      variant={COLOR_CODE_MAP[index + 1]}
                      image={ILLUSTRATION_MAP[index + 1]}
                      selectIssue={() => handleSelectedIssue(issue.id)}
                    />
                  ))}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Issue;
