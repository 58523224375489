import TinyURL from "tinyurl";
import DOMToImage from "dom-to-image";
import { Link } from "react-router-dom";
import { Row, Col, Button, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useContext, useCallback } from "react";

import "./Result.scss";
import ResultSlide from "./ResultSlide";
import useFacts from "../../../hooks/useFacts";
import { getName } from "../../../utils/methods";
import locales from "../../../locales/index.json";
import { AppContext } from "../../../store/AppContext";
import ResultImgEN from "../../../assets/img/results-en.png";
import ResultImgES from "../../../assets/img/results-es.png";
import ResultImgFR from "../../../assets/img/results-fr.png";
import HelpIcon from "../../../assets/img/icons/help.svg";
import ShareModal from "../../../components/common/Modal";
import { COLOR_CODE_MAP } from "../../../utils/constants";
import { getFactImage, uploadFactImage } from "../../../api";
import ShareImg from "../../../assets/img/icons/share-white.svg";

const RESULT_IMGS = {
  en: ResultImgEN,
  es: ResultImgES,
  fr: ResultImgFR,
};

const Results = () => {
  const [tinyUrl, setTinyUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [isPreparingShare, setIsPreparingShare] = useState(true);
  const { issueCategory, selectedLanguage } = useContext(AppContext);
  const { age, country, issue } = issueCategory;

  const { facts } = useFacts({
    country,
    language: selectedLanguage,
    issue,
    age,
  });

  const prepareTinnyUrl = useCallback(async () => {
    const uri = `${process.env.REACT_APP_FRONTEND_URI}/status?country=${country}&issue=${issue}&language=${selectedLanguage}&age=${age}&imageUrl=${thumbnail}`;

    try {
      setTinyUrl(await TinyURL.shorten(uri));
      setIsPreparingShare(false);
    } catch (error) {
      setIsPreparingShare(false);
      message.error(error.message);
    }
  }, [thumbnail, selectedLanguage, age, issue, country]);

  const prepareThumbnail = useCallback(async () => {
    setIsPreparingShare(true);
    const element = document.getElementById("centered-content");
    try {
      const { data } = await getFactImage(
        getName({
          country,
          language: selectedLanguage,
          issue,
          age,
        })
      );
      if (data.url) return setThumbnail(data.url);

      const dataUrl = await DOMToImage.toPng(element);
      const formData = new FormData();
      formData.append("factImage", dataUrl);
      formData.append("age", age);
      formData.append("issue", issue);
      formData.append("language", selectedLanguage);
      formData.append("country", country);

      const response = await uploadFactImage(formData);
      setThumbnail(response.data.url);
    } catch (error) {
      setThumbnail(process.env.REACT_APP_THUMBNAIL_PLACEHOLDER);
      message.error(error.message);
    }
  }, [selectedLanguage, age, issue, country]);

  useEffect(() => {
    !!thumbnail && prepareTinnyUrl();
  }, [thumbnail, prepareTinnyUrl]);

  useEffect(() => {
    if (facts.length > 0) setLoading(false);
  }, [facts.length]);

  return (
    <div className="result-container">
      {showShareModal && (
        <ShareModal
          type="RESULT"
          tinyurl={tinyUrl}
          showModal={showShareModal}
          isPreparingDetails={isPreparingShare}
          closeModal={() => setShowShareModal(false)}
        />
      )}
      <Row className="justify-center">
        <Col xs={24} xl={20}>
          <div className="result-content">
            <div className="heading">
              <Row className="justify-between heading-wrap">
                <div className="progress-bar">
                  {facts.map((fact, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <span key={`${fact.statement}-${index}`} />
                  ))}
                </div>
                <Col xs={22} md={12} xl={null} className="banner-container">
                  <img src={RESULT_IMGS[selectedLanguage]} alt="" />
                </Col>
                <Col xs={22} md={8} xl={8} className="flex help-container">
                  <Link to={`/info/${issue}`} className="help-text">
                    <img
                      src={HelpIcon}
                      alt="help icon"
                      style={{ marginRight: "5px" }}
                    />
                    <span>{locales.findOutMore[selectedLanguage]}</span>
                  </Link>
                </Col>
              </Row>
            </div>
            {loading ? (
              <Row className="justify-center">
                <Col lg={1}>
                  <LoadingOutlined style={{ fontSize: "2rem" }} />
                </Col>
              </Row>
            ) : (
              <div className="result-slide-wrapper">
                <ResultSlide
                  results={facts}
                  theme={COLOR_CODE_MAP[issue]}
                  prepareThumbnail={prepareThumbnail}
                />
              </div>
            )}
            <div className="result-footer">
              <div className="result-btn">
                <Button
                  className="share-btn"
                  onClick={() => setShowShareModal(true)}
                >
                  <img src={ShareImg} alt="" />
                  {locales.shareText[selectedLanguage]}
                </Button>
                <Link to="/explore" className="explore-link">
                  {locales.exploreText[selectedLanguage]}
                </Link>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Results;
