import { useCallback, useContext } from "react";
import { useHistory } from "react-router";

import { AppContext } from "../store/AppContext";
import { COUNTRY, ISSUE, RESULT } from "../utils/constants";

const useUtilities = () => {
  const history = useHistory();
  const { updateIssue, updateNav, issueCategory } = useContext(AppContext);

  const handleSubmitCountry = useCallback(
    (values) => {
      history.push({
        pathname: "/status",
        search: `?age=${issueCategory.age}&country=${values.country}`,
      });
      updateIssue({ ...values });
      updateNav(ISSUE);
    },
    [issueCategory, updateNav, updateIssue, history]
  );

  const handleSelectedIssue = useCallback(
    (issueId) => {
      history.push({
        pathname: "/status",
        search: `?age=${issueCategory.age}&country=${issueCategory.country}&issue=${issueId}`,
      });
      updateIssue({ issue: issueId });
      updateNav(RESULT);
    },
    [issueCategory, updateNav, updateIssue, history]
  );

  const handleSubmitAge = useCallback(
    (values) => {
      history.push({
        pathname: "/status",
        search: `?age=${values.age}`,
      });
      updateIssue({ age: values.age });
      updateNav(COUNTRY);
    },
    [updateNav, updateIssue, history]
  );

  return {
    handleSubmitAge,
    handleSubmitCountry,
    handleSelectedIssue,
  };
};

export default useUtilities;
