/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from "react";
import { Row, Col, Input, Select, Form, Button, message } from "antd";

import * as api from "../../api";
import useUtilities from "../../hooks/useUtilities";
import PageLayout from "../../components/common/PageLayout";

const { Option } = Select;

const CustomSelect = ({ label, options, name, required = false }) => (
  <Form.Item
    name={name}
    label={label}
    rules={[{ required, message: `Please select ${label}` }]}
  >
    <Select
      showSearch
      autoComplete="off"
      optionFilterProp="children"
      placeholder={`select ${name}`}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {options &&
        options.length &&
        options.map((option) => (
          <Option value={option.id} key={option.id}>
            {option.name}
          </Option>
        ))}
    </Select>
  </Form.Item>
);

const CustomInput = ({ label, name, min, max, required = false, type }) => (
  <Form.Item
    name={name}
    label={label}
    rules={[{ required, message: `Please input ${name}` }]}
  >
    <Input min={min || 0} max={max || 180} type={type} autoComplete="off" />
  </Form.Item>
);

const CreateResource = () => {
  const [isloading, setIsLoading] = useState(false);
  const { countries, issues, regions } = useUtilities();

  const [form] = Form.useForm();

  const onReset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const createNewResource = useCallback(async (data) => {
    try {
      setIsLoading(true);
      await api.createFactsResource(data);
      message.success('Resources created successfully.');
      onReset();
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [onReset]);

  return (
    <PageLayout className="info-container" withButtonGroup={false}>
      <div className="info-content-container">
        <Row className="justify-center">
          <Col lg={8}>
            <div className="heading">
              <h3>Add resource</h3>
            </div>
          </Col>
        </Row>
        <Row className="justify-center">
          <Col lg={14} xl={11}>
            <Form
              form={form}
              layout="vertical"
              onFinish={createNewResource}
              className="resource-form-container"
            >

              <Row gutter={16}>
                <Col lg={12}>
                  <CustomInput name="resource" label="Resource name" required />
                </Col>
                <Col lg={12}>
                  <CustomInput label="Resource url" name="url" required />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={12}>
                  <CustomSelect options={countries} name="countryId" label="Country" />
                </Col>
                <Col lg={12}>
                  <CustomSelect options={regions} name="regionId" label="Region" />
                </Col>
              </Row>

              <Row gutter={16}>

                <Col lg={12}>
                  <CustomSelect options={issues} name="thematic1Id" label="Issue 1" />
                </Col>
                <Col lg={12}>
                  <CustomSelect options={issues} name="thematic2Id" label="Issue 2" />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={12}>
                  <CustomInput label="Type of resource" name="typeOfResource" required />
                </Col>
                <Col lg={12}>
                  <CustomInput label="Partner" name="partner" required />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={12}>
                  <CustomInput label="Code" name="code" required />
                </Col>
                <Col lg={12}>
                  <CustomInput label="Language" name="language" required />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={12}>
                  <CustomSelect options={[{id: 'Global', name: 'Global' }, { id: 'Regional', name: 'Regional'}, { id: 'National', name: 'National'}]} label="Region Scope" name="regionScope" />
                </Col>
                <Col lg={12}>
                  <CustomSelect options={[{id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No'}]} label="Covid lense" name="covid19Lens" />
                </Col>
              </Row>

              <Row gutter={16}>
                <Col lg={12}>
                  <CustomInput label="Notes" name="notes" />
                </Col>
                <Col lg={12}>
                  <CustomInput label="Accessibilty token" type="password" name="token" required />
                </Col>
              </Row>

              <Form.Item>
                <Button type="primary" htmlType="submit" className="status-btn" disabled={isloading}>
                  Add resource
                </Button>
                {/* <Button htmlType="reset" className="status-btn">
                  Reset
                </Button> */}
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </PageLayout>
  );
};

export default CreateResource;
