import React, { createContext, useState } from "react";
import { ENGLISH } from "../utils/constants";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [resources, setResources] = useState([]);
  const [currentNav, setCurrentNav] = useState("age");
  const [selectedLanguage, setSelectedLanguage] = useState(ENGLISH);
  const [issueCategory, setIssueCategory] = useState({
    age: null,
    issue: null,
    country: null,
  });

  const updateIssue = (value) =>
    setIssueCategory({ ...issueCategory, ...value });

  const updateNav = (value) => setCurrentNav(value);

  const updateResources = (data) => setResources([...data]);

  const updateLanguage = (value) => setSelectedLanguage(value);

  return (
    <AppContext.Provider
      value={{
        resources,
        updateNav,
        currentNav,
        updateIssue,
        issueCategory,
        updateLanguage,
        updateResources,
        selectedLanguage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
