import axios from "axios";

const request = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URI });

export const getFactImage = (id) => request(`facts/image/${id}`);

export const getFactsList = (params) => request("facts", { params });

export const getFactsResources = (params) => request("resources", { params });

export const createFactsResource = (payload) =>
  request.post("resources", payload);

export const uploadFactImage = (payload) =>
  request.post(`facts/image-upload`, payload);

export const getResource = ({ queryKey: [url, params] }) =>
  request(url, { params });
