import { Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";
import React, { useContext, useCallback } from "react";

import "./Common.scss";
import Navbar from "./Navbar";
// eslint-disable-next-line import/extensions
import locales from "../../locales";
import { AppContext } from "../../store/AppContext";

const PageLayout = ({ children, page = "", withButtonGroup = true }) => {
  const { currentNav, issueCategory, updateNav, selectedLanguage } =
    useContext(AppContext);
  const history = useHistory();
  const currentRoute = window.location.pathname;

  const handleNav = useCallback(
    (value) => {
      if (currentRoute !== "/status") {
        history.replace("/status");
        updateNav("age");
      } else {
        updateNav(value);
      }
    },
    [currentRoute, history, updateNav]
  );

  return (
    <div className="page-layout-container">
      <Navbar />
      {currentRoute !== "/" ? (
        <div className="status-container">
          {withButtonGroup && (
            <Row className="justify-center">
              <Col xs={22} md={12} lg={10} xl={8}>
                <div className="nav-heading">
                  <Button
                    className={`nav-item ${
                      currentNav === "age" ? " active-nav" : ""
                    }${issueCategory.age !== null ? " visited-nav " : ""}${
                      currentNav === "country" ? " no-border" : ""
                    }`}
                    onClick={() => handleNav("age")}
                  >
                    {locales.ageNavTitle[selectedLanguage]}
                  </Button>
                  <Button
                    className={`nav-item ${
                      currentNav === "country" ? " active-nav" : ""
                    }${issueCategory.Country !== "" ? " visited-nav " : ""}${
                      currentNav === "issue" ? " no-border" : ""
                    }`}
                    disabled={issueCategory.country === null}
                    onClick={() => handleNav("country")}
                  >
                    {locales.countriesNavTitle[selectedLanguage]}
                  </Button>
                  <Button
                    className={`nav-item ${
                      issueCategory.issue !== "" ? " visited-nav " : ""
                    }${currentNav === "issue" ? " active-nav" : ""}${
                      currentNav === "result" ? " no-border" : ""
                    }`}
                    disabled={issueCategory.issue === null}
                    onClick={() => handleNav("issue")}
                  >
                    {locales.issueNavTitle[selectedLanguage]}
                  </Button>
                  <Button
                    className={`nav-item ${
                      currentNav === "result" ? " active-nav" : ""
                    }`}
                    disabled={currentNav !== "result"}
                  >
                    {locales.resultsNavTitle[selectedLanguage]}
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>
      ) : null}
      <div className={`page-content-container ${page}`}>{children}</div>
    </div>
  );
};

export default PageLayout;
