import React from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

import "./Explore.scss";
import ExploreCard from "./ExploreCard";
import PageLayout from "../../components/common/PageLayout";

const Resources = () => {
  const resources = localStorage.getItem("EM_RESOURCES");
  if (!resources) return null;
  const resourceArr = JSON.parse(resources);
  return resourceArr.map((item) => {
    if (item === null) return null;
    return (
      <Col xs={24} md={12} xl={8} key={item.id}>
        <ExploreCard
          ogImage={item.ogImage}
          title={item.partner}
          resourceLink={item.url}
          bodyContent={item.resource}
          language={item.language}
        />
      </Col>
    );
  });
};

const Explore = () => (
  <PageLayout className="explore-container">
    <div className="explore-content-container">
      <Row className="justify-center">
        <Col xs={22} md={16} lg={16} xl={10}>
          <div className="heading">
            <h3>Explore</h3>
          </div>
        </Col>
      </Row>
      <Row className="justify-center">
        <Col xs={22} md={22} xl={18}>
          <div className="content">
            <Row gutter={16}>
              <Resources />
            </Row>
            <Row className=" justify-center">
              <Col lg={5} className="explore-link-btn">
                <Link to="/status">Back to results</Link>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  </PageLayout>
);

export default Explore;
