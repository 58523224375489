import React from "react";
import { Modal } from "antd";
import {
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from "@ant-design/icons";

import "./Modal.scss";

const shareData = (type, url) => {
  const TITLE =
    type === "HOME"
      ? process.env.REACT_APP_INDEX_PAGE_SHARE_TEXT
      : process.env.REACT_APP_RESULT_PAGE_SHARE_TEXT;

  const HASHTAGS =
    type === "HOME"
      ? process.env.REACT_APP_HOME_SHARE_HASHTAGS
      : process.env.REACT_APP_RESULT_PAGE_SHARE_HASHTAGS;

  const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
  const URL = type === "HOME" ? process.env.REACT_APP_FRONTEND_URI : url;

  return [
    {
      cssClass: "fb",
      Icon: FacebookOutlined,
      url: `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=page&href=${URL}&quote=${TITLE}&hashtag=${HASHTAGS}`,
    },
    {
      cssClass: "twitter",
      Icon: TwitterOutlined,
      url: `https://twitter.com/share?url=${URL}&text=${TITLE}&hashtags=${HASHTAGS}`,
    },
    {
      cssClass: "ln",
      Icon: LinkedinOutlined,
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${URL}&title=${TITLE}`,
    },
  ];
};

const Index = ({
  showModal,
  closeModal,
  tinyurl,
  type,
  isPreparingDetails = false,
}) => (
  <Modal
    visible={showModal}
    onCancel={closeModal}
    className="em-modal-container"
    title={<h3>Share</h3>}
  >
    <div className="social-list">
      {isPreparingDetails ? (
        <div>preparing to share...</div>
      ) : (
        <>
          {shareData(type, tinyurl).map(({ cssClass, url, Icon }) => (
            <a
              key={url}
              target="_blank"
              rel="noreferrer"
              className={cssClass}
              href={url}
            >
              <Icon />
            </a>
          ))}
        </>
      )}
    </div>
  </Modal>
);

export default Index;
