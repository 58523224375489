import React from "react";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";

import HomePage from "../pages/Home";
import InfoPage from "../pages/Info";
import StatusPage from "../pages/Status";
import ExplorePage from "../pages/Explore";
import CreateResources from "../pages/Resources/create";
//
const Routes = () => {
  const history = useHistory();
  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/status" component={StatusPage} />
        <Route exact path="/explore" component={ExplorePage} />
        <Route exact path="/info/:id" component={InfoPage} />
        <Route exact path="/resources/create" component={CreateResources} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
