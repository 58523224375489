import { Row, Col, Select } from "antd";
import React, { useContext } from "react";

import "./Navbar.scss";
import Logo from "../Logo";
import { AppContext } from "../../../store/AppContext";
import { ENGLISH, FRENCH, SPANISH } from "../../../utils/constants";
import { ReactComponent as EnglishIcon } from "../../../assets/img/icons/english.svg";
import { ReactComponent as CaretDownIcon } from "../../../assets/img/icons/caret-down.svg";

const { Option } = Select;

const Navbar = () => {
  const { updateLanguage, selectedLanguage } = useContext(AppContext);
  return (
    <Row justify="center" className="nav-container">
      <Col xs={23} lg={20}>
        <Row justify="space-between" className="items-center">
          <Col xs={15} lg={6}>
            <div className="logo">
              <Logo />
              {(process.env.REACT_APP_ENABLE_LANGUAGE_OPTIONS === "true" ||
                process.env.REACT_APP_CI_TEST === "true") && (
                <div className="language-selector">
                  <Select
                    defaultValue={selectedLanguage}
                    onChange={(value) => updateLanguage(value)}
                    suffixIcon={<CaretDownIcon />}
                  >
                    <Option value={ENGLISH}>
                      <EnglishIcon />
                      <span className="language-text">ENG</span>
                    </Option>
                    <Option value={FRENCH}>FR</Option>
                    <Option value={SPANISH}>ES</Option>
                  </Select>
                </div>
              )}
            </div>
          </Col>

          <Col xs={9} lg={6} className="link-btn-container">
            <a
              href="https://www.equalmeasures2030.org/data-hub/"
              target="_blank"
              rel="noreferrer"
              className="link-btn"
            >
              Data Hub
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Navbar;
