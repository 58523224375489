import Illustration1 from "../assets/img/illustration_1.png";
import Illustration2 from "../assets/img/illustration_2.png";
import Illustration3 from "../assets/img/illustration_3.png";
import Illustration4 from "../assets/img/illustration_4.png";
import Illustration5 from "../assets/img/illustration_5.png";

export const AGE = "age";
export const ISSUE = "issue";
export const RESULT = "result";
export const COUNTRY = "country";

export const FRENCH = "fr";
export const ENGLISH = "en";
export const SPANISH = "es";

export const STORAGE_ALIAS = {
  facts: "EM_FACTS",
  resources: "EM_RESOURCES",
};

export const ENDPOINTS = {
  facts: "facts",
  issues: "issues",
  regions: "regions",
  countries: "countries",
};

export const COLOR_CODE_MAP = {
  1: "danger",
  2: "teal",
  3: "blue",
  4: "yellow",
  5: "gray",
};

export const ILLUSTRATION_MAP = {
  1: Illustration1,
  2: Illustration2,
  3: Illustration3,
  4: Illustration4,
  5: Illustration5,
};

export const findOutMore = {
  [ENGLISH]: {
    issue1: {
      note: "The age at which the gender-related target will be achieved is an illustrative forecast based on past data trends and assuming that these trends will continue into the future.",
      details: [
        "Access to safe, voluntary modern methods of contraception is central to women’s life choices, livelihoods and opportunities.",
        "The indicator used to measure access is the proportion of women of reproductive age (aged 15–49 years) who have their need for family planning satisfied with modern methods. It is indicator reference Ind. 3.3 in the 2022 SDG Gender Index and is part of the official SDG 3 indicator framework (SDG 3.7.1).",
        "The data source is the United Nations Population Division and projections are based, for all countries, on data between 2000 to 2021, or 22 annual data points.",
        "These figures reflect new UN data that measures access to family planning for both married and unmarried women, thus it is not consistent with the data used earlier in EM2030’s Bending the Curve report.",
        "The projections to 2030 are based on a linear regression that calculates the best-fitted trend line among the annual values since 2000. This trend is then used to calculate the estimated value in 2030 as well as the estimated year in which the target would be reached given the past trend remains the same into the future.",
      ],
    },
    issue2: {
      note: "The age at which the gender-related target will be achieved is an illustrative forecast based on past data trends and assuming that these trends will continue into the future.",
      details: [
        "Attaining secondary education is a fundamental right for girls because it is vital for individual life opportunities and self-development and also builds stronger economies and societies.",
        "The indicator used is the proportion of women (25+ years) with at least some secondary education.",
        "This is indicator reference Ind. 4.4 in the 2022 SDG Gender Index and is related to SDG indicators for Goal 4.",
        "The data sources are UNESCO and Barro and Lee accessed through UNDP and projected data are based on available data between 2000 to 2019. On average, country projections are based on 13 annual data points.",
        "These figures reflect a different indicator related to women’s educational attainment that has better country coverage, thus it is not consistent with the data used earlier in EM2030’s Bending the Curve report.",
        "The projections to 2030 are based on a linear regression that calculates the best-fitted trend line among the annual values since 2000. This trend is then used to calculate the estimated value in 2030 as well as the estimated year in which the target would be reached given the past trend remains the same into the future.",
      ],
    },
    issue3: {
      note: "The age at which the gender-related target will be achieved is an illustrative forecast based on past data trends and assuming that these trends will continue into the future.",
      details: [
        "This indicator captures the extent to which countries have laws that protect women against discrimination in the workforce and comprises seven legal areas (including anti-discrimination, equal pay, paid leave, treatment of pregnant workers, and legal restrictions on the types of jobs women can do) as assessed and compiled by the World Bank’s Women, Business and the Law (WBL) project.",
        "This is indicator reference Ind. 8.4 in the 2022 SDG Gender Index and is related to SDG indicators for Goals 5 and 8.",
        "The data source is WBL and projections are based, for all countries, on data between 2000 to 2021, or 22 annual data points.",
        "The projections to 2030 are based on a linear regression that calculates the best-fitted trend line among the annual values since 2000. This trend is then used to calculate the estimated value in 2030 as well as the estimated year in which the target would be reached given the past trend remains the same into the future.",
      ],
    },
    issue4: {
      note: "The age at which the gender-related target will be achieved is an illustrative forecast based on past data trends and assuming that these trends will continue into the future.",
      details: [
        "Gender-based violence is persistent in all countries of the world and the impact of such violence on women’s physical and mental health is profound.",
        "While comparable global data on gender-based violence are not available for most countries; women’s perceptions of their own safety is one – albeit imperfect – proxy for gender-based violence.",
        'The indicator used is the percentage of women aged 15 years and older who report that they "feel safe walking alone at night in the city or area where you live."',
        "This is indicator reference Ind. 16.3 in the 2022 SDG Gender Index and an official SDG indicator for Goal 16 (16.1.4).",
        "The data source is Gallup and projections are based on available data between 2006 to 2020. On average, country projections are based on 13 annual data points.",
        "The projections to 2030 are based on a linear regression that calculates the best-fitted trend line among the annual values since 2006. This trend is then used to calculate the estimated value in 2030 as well as the estimated year in which the target would be reached given the past trend remains the same into the future.",
      ],
    },
  },
  [SPANISH]: {
    issue1: {
      note: "La edad en la cual se alcanzará la meta relacionada con el género es un pronóstico ilustrativo que se sustenta en las tendencias observadas en datos anteriores y bajo el supuesto de que estas tendencias se mantendrán en el futuro",
      details: [
        "El acceso a métodos anticonceptivos modernos, seguros y voluntarios es fundamental para que las mujeres puedan decidir sobre su vida, aprovechar las oportunidades y sostener sus medios de subsistencia.",
        "El indicador utilizado para medir el acceso es la proporción de mujeres en edad reproductiva (entre 15 y 49 años) que cubren sus necesidades de planificación familiar con métodos modernos. Este es el indicador de referencia 3.3 en el Índice de Género de los ODS de 2022 y forma parte del marco oficial de indicadores para el ODS 3 (ODS 3.7.1).",
        "La fuente de datos es la División de Población de las Naciones Unidas, y las proyecciones para todos los países se basan en datos que abarcan de 2000 a 2021, o bien, 22 puntos de datos anuales.",
        "Estas cifras reflejan los nuevos datos de Naciones Unidas que miden el acceso a métodos de planificación familiar para mujeres casadas y no casadas, por lo que son consistentes con los datos utilizados anteriormente en el reporte Cambiando la Tendencia de EM2030.",
        "Las proyecciones para 2030 se basan en una regresión lineal que calcula la línea de tendencia que mejor se ajusta entre los valores anuales desde el año 2000. Esta tendencia luego se utiliza para calcular el valor estimado en 2030, así como el año previsto en el cual se alcanzará la meta, siempre y cuando las tendencias pasadas sigan siendo las mismas en el futuro.",
      ],
    },
    issue2: {
      note: "La edad en la cual se alcanzará la meta relacionada con el género es un pronóstico ilustrativo que se sustenta en las tendencias observadas en datos anteriores y bajo el supuesto de que estas tendencias se mantendrán en el futuro",
      details: [
        "Alcanzar la educación secundaria es un derecho fundamental para las niñas; es vital para las oportunidades de vida y desarrollo personal, y también, para forjar sociedades y economías más sólidas.",
        "El indicador utilizado es la proporción de mujeres (de 25 años y más) con al menos algún nivel de educación secundaria.",
        "Este es el indicador de referencia 4.4 en el Índice de Género de los ODS de 2022 y guarda relación con los indicadores del ODS 4.",
        "Las fuentes de datos son la Organización de las Naciones Unidas para la Educación, la Ciencia y la Cultura (UNESCO) y el conjunto de datos de Barro y Lee, que se consultaron por medio del Programa de las Naciones Unidas para el Desarrollo (PNUD). Las proyecciones de datos se basan en los datos disponibles entre 2000 y 2019. En promedio, las proyecciones de los países se basan en 13 puntos de datos anuales.",
        "Estas cifras reflejan un indicador diferente, relativo al nivel educativo de las mujeres, que tiene mejor cobertura de los países. Por lo tanto, no coinciden con los datos utilizados anteriormente en el reporte Cambiando la Tendencia de EM2030.",
        "Las proyecciones para 2030 se basan en una regresión lineal que calcula la línea de tendencia que mejor se ajusta entre los valores anuales desde el año 2000. Esta tendencia luego se utiliza para calcular el valor aproximado en 2030, así como el año previsto en el cual se alcanzará la meta, siempre y cuando las tendencias pasadas sigan siendo las mismas en el futuro. ",
      ],
    },
    issue3: {
      note: "La edad en la cual se alcanzará la meta relacionada con el género es un pronóstico ilustrativo, que se sustenta en las tendencias observadas en datos anteriores y bajo el supuesto de que estas tendencias se mantendrán en el futuro.",
      details: [
        "Este indicador —que refleja en qué medida los países tienen leyes que protegen a las mujeres de la discriminación en el ámbito laboral— comprende siete esferas jurídicas (entre ellas, la no discriminación, la igualdad salarial, las licencias remuneradas, el trato hacia las trabajadoras embarazadas y las restricciones legales sobre los tipos de trabajo que las mujeres pueden desempeñar), según lo que evalúa y compila el proyecto Mujeres, Empresas y el Derecho del Banco Mundial.",
        "Este es el indicador de referencia 8.4 del Índice de Género de los ODS de 2022 y guarda relación con los indicadores de los ODS 5 y 8.",
        "La fuente de datos es el proyecto Mujeres, Empresas y el Derecho, y las proyecciones para todos los países se basan en datos que abarcan de 2000 a 2021, o bien, 22 puntos de datos anuales.",
        "Las proyecciones para 2030 se basan en una regresión lineal que calcula la línea de tendencia que mejor se ajusta entre los valores anuales desde el año 2000. Esta tendencia luego se utiliza para calcular el valor estimado en 2030, así como el año previsto en el cual se alcanzará la meta, siempre y cuando las tendencias pasadas sigan siendo las mismas en el futuro.",
      ],
    },
    issue4: {
      note: "La edad en la cual se alcanzará la meta relacionada con el género es un pronóstico ilustrativo, que se sustenta en las tendencias observadas en datos anteriores y bajo el supuesto de que estas tendencias se mantendrán en el futuro.",
      details: [
        "La violencia de género es persistente en todos los países del mundo, y el impacto de dicha violencia en la salud física y mental de las mujeres es profundo.",
        "Si bien la mayoría de los países carecen de datos globales comparables sobre la violencia de género, la percepción de las mujeres sobre su propia seguridad es un indicador — aunque imperfecto — de la violencia de género.",
        "El indicador utilizado es el porcentaje de mujeres de 15 años y más que informan que “se sienten seguras caminando solas de noche en la ciudad o zona donde viven”.",
        "Este es el indicador de referencia 16.3 del Índice de Género de los ODS de 2022 y un indicador oficial del ODS 16 (16.1.4).",
        "La fuente de datos es Gallup, y las proyecciones se basan en los datos disponibles entre 2006 y 2020. En promedio, las proyecciones de los países se basan en 13 puntos de datos anuales.",
        "Las proyecciones para 2030 se basan en una regresión lineal que calcula la línea de tendencia que mejor se ajusta entre los valores anuales desde 2006. Esta tendencia luego se utiliza para calcular el valor estimado en 2030, así como el año previsto en el cual se alcanzará la meta, siempre y cuando las tendencias pasadas sigan siendo las mismas en el futuro. ",
      ],
    },
  },
  [FRENCH]: {
    issue1: {
      note: "L'âge auquel l'objectif, lié au genre, sera atteint est une projection illustrative basée sur les tendances des données passées tout en supposant que ces tendances se poursuivront dans le futur.",
      details: [
        "L'accès à des méthodes modernes de contraception sûres et volontaires est essentiel pour les choix de vie, les moyens de subsistance et les opportunités des femmes.",
        "L'indicateur utilisé pour mesurer l'accès est la proportion de femmes en âge de procréer (15-49 ans) qui voient leur besoin de planification familiale satisfait par des méthodes modernes. Il s'agit de l'indicateur de référence Ind. 3.3 de l’Indice du Genre dans les ODD 2022 et fait partie du des indicateurs officiels de l'ODD 3 (ODD 3.7.1).",
        "La source de données est la Division de la population des Nations Unies et les projections sont basées, pour tous les pays, sur des données entre 2000 et 2021, soit 22 points de données annuels.",
        "Ces chiffres reflètent les nouvelles données des Nations Unies qui mesurent l'accès au planning familial pour les femmes mariées et non mariées, et ne correspondent donc pas aux données utilisées précédemment dans le rapport Bending the Curve de l'EM2030.",
        "Les projections jusqu'en 2030 sont basées sur une régression linéaire qui calcule la ligne de tendance la mieux ajustée parmi les valeurs annuelles depuis 2000. Cette tendance est ensuite utilisée pour calculer la valeur estimée en 2030 ainsi que l'année estimée à laquelle l'objectif serait atteint si la tendance passée reste la même dans le futur.",
      ],
    },
    issue2: {
      note: "L'âge auquel l'objectif, lié au genre, sera atteint est une projection illustrative basée sur les tendances des données passées tout en supposant que ces tendances se poursuivront dans le futur.",
      details: [
        "L'accès à l'enseignement secondaire est un droit fondamental pour les filles, car il est vital pour les opportunités de vie individuelles et l'épanouissement personnel et permet également de construire des économies et des sociétés plus fortes.",
        "L'indicateur utilisé est la proportion de femmes (25 ans et plus) ayant au moins atteint une partie de l’enseignement secondaire.",
        "Il s'agit de l'indicateur de référence Ind. 4.4 dans l'Indice du Genre dans les ODD 2022 et est lié aux indicateurs des ODD pour l'objectif 4.",
        "Les sources de données sont l'UNESCO et Barro and Lee, accessibles par le biais du PNUD, et les données projetées sont basées sur des données disponibles entre 2000 et 2019. En moyenne, les projections par pays sont basées sur 13 points de données annuels.",
        "Cet indicateur lié au niveau d'éducation des femmes bénéficie d'une bonne couverture nationale et c’est pourquoi il a été choisi et ne correspond donc plus avec les données utilisées précédemment dans le rapport Bending the Curve de l'EM2030.",
        "Les projections jusqu'en 2030 sont basées sur une régression linéaire qui calcule la ligne de tendance la mieux ajustée parmi les valeurs annuelles depuis 2000. Cette tendance est ensuite utilisée pour calculer la valeur estimée en 2030 ainsi que l'année estimée à laquelle l'objectif serait atteint si la tendance passée reste la même dans le futur.",
      ],
    },
    issue3: {
      note: "L'âge auquel l'objectif, lié au genre, sera atteint est une projection illustrative basée sur les tendances des données passées tout en supposant que ces tendances se poursuivront dans le futur.",
      details: [
        "Cet indicateur évalue dans quelle mesure les pays disposent de lois qui protègent les femmes contre la discrimination sur le marché du travail. Il comprend sept domaines juridiques (dont l'anti-discrimination, l'égalité de rémunération, les congés payés, le traitement des travailleuses enceintes et les restrictions légales sur les types d'emplois que les femmes peuvent occuper) évalués et compilés par le projet Les Femmes, l’Entreprise et le Droit (Women, Business and the Law (WBL)) de la Banque mondiale.",
        "Il s'agit de l'indicateur de référence Ind. 8.4 de l'Indice du Genre dans les ODD 2022 et est lié aux indicateurs des ODD pour les objectifs 5 et 8. ",
        "La source de données est WBL et les projections sont basées, pour tous les pays, sur des données entre 2000 et 2021, soit 22 points de données annuels.",
        "Les projections jusqu'en 2030 sont basées sur une régression linéaire qui calcule la ligne de tendance la mieux ajustée parmi les valeurs annuelles depuis 2000. Cette tendance est ensuite utilisée pour calculer la valeur estimée en 2030 ainsi que l'année estimée à laquelle l'objectif serait atteint si la tendance passée reste la même dans le futur.",
      ],
    },
    issue4: {
      note: "L'âge auquel l'objectif, lié au genre, sera atteint est une projection illustrative basée sur les tendances des données passées tout en supposant que ces tendances se poursuivront dans le futur.",
      details: [
        "La violence fondée sur le genre est persistante dans tous les pays du monde et l'impact de cette violence sur la santé physique et mentale des femmes est profond.",
        "Bien que des données mondiales comparables sur la violence fondée sur le genre ne soient pas disponibles pour la plupart des pays, la perception qu'ont les femmes de leur propre sécurité est un indicateur - bien qu'imparfait - de cette violence.",
        "L'indicateur utilisé est le pourcentage de femmes âgées de 15 ans et plus qui déclarent \"se sentir en sécurité lorsqu'elles marchent seules la nuit dans la ville ou le quartier où elles vivent\".",
        "Il s'agit de l'indicateur de référence Ind. 16.3 de l'Indice du Genre dans les ODD 2022 et un indicateur officiel de l'objectif 16 (16.1.4).",
        "La source des données est Gallup et les projections sont basées sur les données disponibles entre 2006 et 2020. En moyenne, les projections par pays sont basées sur 13 points de données annuels.",
        "Les projections jusqu'en 2030 sont basées sur une régression linéaire qui calcule la ligne de tendance la mieux ajustée parmi les valeurs annuelles depuis 2006. Cette tendance est ensuite utilisée pour calculer la valeur estimée en 2030 ainsi que l'année estimée à laquelle l'objectif serait atteint si la tendance passée reste la même dans le futur.",
      ],
    },
  },
};
