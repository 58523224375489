import { Link } from "react-router-dom";
import { Row, Col, Button } from "antd";
import React, { useState, useContext } from "react";

import "./Home.scss";
import locales from "../../locales/index.json";
import { AppContext } from "../../store/AppContext";
import ShareModal from "../../components/common/Modal";
import ShareIcon from "../../assets/img/icons/share.svg";
import backDropSrc from "../../assets/img/backdrop-1.png";
import PageLayout from "../../components/common/PageLayout";

const Home = () => {
  const [showShareModal, setShowShareModal] = useState(false);
  const { selectedLanguage } = useContext(AppContext);

  return (
    <PageLayout>
      <div className="homepage-container">
        {showShareModal && (
          <ShareModal
            type="HOME"
            showModal={showShareModal}
            closeModal={() => setShowShareModal(false)}
          />
        )}
        <Row className="justify-center ">
          <Col xs={24} lg={12} xl={12} xxl={10}>
            <div className="hero-section">
              <h2>{locales.areWeThereYetTitle[selectedLanguage]}</h2>
              <p>{locales.areWeThereYetDescription[selectedLanguage]}</p>
              <div className="btn-container">
                <Link to="/status">{locales.startText[selectedLanguage]}</Link>
                <Button onClick={() => setShowShareModal(true)}>
                  <img src={ShareIcon} alt="share icon" />
                  <span>{locales.shareText[selectedLanguage]}</span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <div className="backdrop">
          <img src={backDropSrc} alt="background illustration" />
        </div>
      </div>
    </PageLayout>
  );
};

export default Home;
